<template>
    <div class="flex items-center justify-center w-full">
        <div class="w-full mb-4 page_box">
            <div class="text-left text-gray-500 path">
                <navbar-text :navbarList="navbarList"></navbar-text>
            </div>
            <div class="flex items-center justify-start drive_title_box">
                <img src="../../../../assets/images/serve/drive_icon.png" alt />
                <div class="items-center drive_title">驱动下载</div>
            </div>
            <div class="flex items-center w-full tabs">
                <div style="width:33.33%" class="flex items-center justify-center h-full cursor-pointer"
                    v-for="(item, index) in tabs" :key="index" :class="tabsIndex == index ? 'bg-primary text-white' : ''"
                    @click="tabsIndex = index">{{ item }}
                </div>
            </div>
            <div>
                <div class="w-full" v-show="tabsIndex == 0">
                    <div class="flex flex-wrap items-center w-full select_box">
                        <div class="select_item">
                            <div class="pc_select_show">
                                <el-select v-model="seriesId" placeholder="请选择系列" style="width:98%">
                                    <el-option v-for="item in seriesList" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <div class="phone_select_show">
                                <div class="flex form_input custom_input" @click="openPopup('mobilepopupSeries')"
                                    style="width:100%">
                                    <span :class="seriesId == '' ? 'text-gray-300' : ''">
                                        {{
                                            seriesId == "" ? "请选择系列" : transformSelect(seriesList, seriesId)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="select_item" v-if="seriesId">
                            <div class="pc_select_show">
                                <el-select v-model="modelId" placeholder="请选择型号" style="width:98%">
                                    <el-option v-for="item in model" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <div class="phone_select_show">
                                <div class="flex ml-1 form_input custom_input" @click="openPopup('mobilepopup1')"
                                    style="width:100%">
                                    <span :class="modelId == '' ? 'text-gray-300' : ''">
                                        {{
                                            modelId == "" ? "请选择型号" : transformSelect(model, modelId)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="select_item" v-if="modelId">
                            <div class="pc_select_show">
                                <el-select v-model="systemId" placeholder="请选择版本" style="width:98%">
                                    <el-option v-for="item in system" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <div class="phone_select_show">
                                <div class="flex form_input custom_input" @click="openPopup('mobilepopup4')"
                                    style="width:100%">
                                    <span :class="systemId == '' ? 'text-gray-300' : ''">
                                        {{
                                            systemId == "" ? "请选择版本" : transformSelect(system, systemId)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full" v-show="tabsIndex == 1">
                    <div class="flex flex-wrap items-center w-full select_box">
                        <!-- <div class="select_item_two">
                            <div class="pc_select_show">
                                <el-select
                                    v-model="peripheralId"
                                    placeholder="请选择外设类型"
                                    style="width:100%"
                                >
                                    <el-option
                                        v-for="item in peripheral"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="phone_select_show">
                                <div
                                    class="flex form_input custom_input"
                                    @click="openPopup('mobilepopup6')"
                                    style="width:100%"
                                >
                                    <span :class="peripheralId == '' ? 'text-gray-300' : ''">
                                        {{
                                            peripheralId == "" ? "请选择外设类型" : transformSelect(peripheral, peripheralId)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>-->
                        <div class="select_item_two">
                            <div class="pc_select_show">
                                <el-select v-model="psId" placeholder="请选择系列" style="width:98%">
                                    <el-option v-for="item in psList" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <div class="phone_select_show">
                                <div class="flex form_input custom_input" @click="openPopup('pSeries')" style="width:100%">
                                    <span :class="psId == '' ? 'text-gray-300' : ''">
                                        {{
                                            psId == "" ? "请选择系列" : transformSelect(psList, psId)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="select_item_two" v-if="psId">
                            <div class="pc_select_show">
                                <el-select v-model="pmId" placeholder="请选择型号" style="width:98%">
                                    <el-option v-for="item in pModel" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <div class="phone_select_show">
                                <div class="flex ml-1 form_input custom_input" @click="openPopup('pModel')"
                                    style="width:100%">
                                    <span :class="pmId == '' ? 'text-gray-300' : ''">
                                        {{
                                            pmId == "" ? "请选择型号" : transformSelect(pModel, pmId)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="select_item_two" v-if="pmId">
                            <div class="pc_select_show">
                                <el-select v-model="pSysId" placeholder="请选择版本" style="width:98%">
                                    <el-option v-for="item in pSystem" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <div class="phone_select_show">
                                <div class="flex form_input custom_input" @click="openPopup('pSystem')" style="width:100%">
                                    <span :class="pSysId == '' ? 'text-gray-300' : ''">
                                        {{
                                            pSysId == "" ? "请选择版本" : transformSelect(pSystem, pSysId)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="w-full" v-show="tabsIndex == 2">
                    <div class="flex items-center justify-center w-full select_box">
                        <div class="select_item_two">
                            <div class="pc_select_show">
                                <el-select
                                    v-model="toolId"
                                    placeholder="请选择常用工具类型"
                                    style="width:100%"
                                >
                                    <el-option
                                        v-for="item in tools"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="phone_select_show">
                                <div
                                    class="flex form_input custom_input"
                                    @click="openPopup('mobilepopup7')"
                                    style="width:100%"
                                >
                                    <span :class="toolId == '' ? 'text-gray-300' : ''">
                                        {{
                                            toolId == "" ? "请选择外设类型" : transformSelect(tools, toolId)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
            <transition name="result">
                <div class="table w-full">
                    <div class="flex items-center w-full duration-500 table_title">
                        <div v-for="(item, index) in tableTitle" :key="index" class="flex items-center h-full duration-500"
                            style="width:25%" :class="index == 0
                                    ? 'justify-start'
                                    : index == 3
                                        ? 'justify-end'
                                        : 'justify-center'
                                ">{{ item }}</div>
                    </div>
                    <div class="w-full" v-if="tabsIndex == 0">
                        <div v-if="conditionsResult.length > 0">
                            <ul class="w-full">
                                <li class="flex items-center w-full duration-500" v-for="(item, index) in conditionsResult"
                                    :key="index">
                                    <div class="flex items-center justify-start duration-500 li_item">{{
                                        item.driveName
                                    }}</div>
                                    <!-- <div
                                        class="flex items-center justify-center duration-500 li_item"
                                    >{{ item.fileSize }}M</div>-->
                                    <div class="flex items-center justify-center duration-500 li_item">{{
                                        item.fileSize
                                    }}</div>
                                    <div class="flex items-center justify-center duration-500 cursor-pointer li_item hover:text-primary text-primary"
                                        @click="download(item)">立即下载</div>
                                    <div class="flex items-center justify-end duration-500 li_item">{{
                                        item.updTime ?
                                        dealWith(item.updTime) : '--'
                                    }}</div>
                                </li>
                            </ul>
                        </div>
                        <div v-else class="flex items-center justify-center w-full py-4 text-gray-400 bg-white">暂无数据
                        </div>
                    </div>
                    <div class="w-full" v-if="tabsIndex == 1">
                        <div v-if="peripheralResult.length > 0">
                            <ul class="w-full">
                                <li class="flex items-center w-full duration-500" v-for="(item, index) in peripheralResult"
                                    :key="index">
                                    <div class="flex items-center justify-start duration-500 li_item">{{
                                        item.driveName
                                    }}</div>
                                    <!-- <div
                                        class="flex items-center justify-center duration-500 li_item"
                                    >{{ item.fileSize }}M</div>-->
                                    <div class="flex items-center justify-center duration-500 li_item">{{
                                        item.fileSize
                                    }}</div>
                                    <div class="flex items-center justify-center duration-500 cursor-pointer li_item hover:text-primary text-primary"
                                        @click="download(item)">立即下载</div>
                                    <div class="flex items-center justify-end duration-500 li_item">{{
                                        item.updTime ?
                                        dealWith(item.updTime) : '--'
                                    }}</div>
                                </li>
                            </ul>
                        </div>
                        <div v-else class="flex items-center justify-center w-full py-4 text-gray-400 bg-white">暂无数据
                        </div>
                    </div>
                    <div class="w-full" v-if="tabsIndex == 2">
                        <ul class="w-full" v-if="toolResult.length > 0">
                            <li class="flex items-center w-full duration-500" v-for="(item, index) in toolResult"
                                :key="index">
                                <div class="flex items-center justify-start duration-500 li_item">{{ item.driveName }}
                                </div>
                                <!-- <div
                                    class="flex items-center justify-center duration-500 li_item"
                                >{{ item.fileSize }}M</div>-->
                                <div class="flex items-center justify-center duration-500 li_item">{{ item.fileSize }}
                                </div>
                                <div class="flex items-center justify-center duration-500 cursor-pointer li_item hover:text-primary text-primary"
                                    @click="download(item)">立即下载</div>
                                <div class="flex items-center justify-end duration-500 li_item">{{
                                    item.updTime ?
                                    dealWith(item.updTime) : '--'
                                }}</div>
                            </li>
                        </ul>
                        <div v-else class="flex items-center justify-center w-full py-4 text-gray-400 bg-white">暂无数据
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <MobilePopup ref="mobilepopupSeries" :list="seriesList" @select="selectFuncSeries"></MobilePopup>
        <MobilePopup ref="mobilepopup1" :list="model" @select="selectFunc1"></MobilePopup>
        <MobilePopup ref="mobilepopup4" :list="system" @select="selectFunc4"></MobilePopup>
        <MobilePopup ref="mobilepopup6" :list="system" @select="selectFunc6"></MobilePopup>
        <MobilePopup ref="pSeries" :list="psList" @select="selectPSeries"></MobilePopup>
        <MobilePopup ref="pModel" :list="pModel" @select="selectPModel"></MobilePopup>
        <MobilePopup ref="pSystem" :list="pSystem" @select="selectPSystem"></MobilePopup>

        <!-- <MobilePopup ref="mobilepopup" :list="system" @select="selectFunc5"></MobilePopup> -->
        <!-- <MobilePopup ref="mobilepopup2" :list="cpu" @select="selectFunc2"></MobilePopup>
        <MobilePopup ref="mobilepopup3" :list="memory" @select="selectFunc3"></MobilePopup>-->
        <!-- <MobilePopup ref="mobilepopup7" :list="tools" @select="selectFunc7"></MobilePopup> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            // "型号查询",
            tabs: ["电脑产品驱动", "外设驱动", "常用工具"],
            // tableTitle: ["驱动名称", "内存", "下载链接", "更新日期"],
            tableTitle: ["驱动名称", '文件大小', "下载链接", "更新日期"],
            tabsIndex: 0,
            isSearch: false,
            options: [
                {
                    value: "选项1",
                    label: "黄金糕",
                },
                {
                    value: "选项2",
                    label: "双皮奶",
                },
                {
                    value: "选项3",
                    label: "蚵仔煎",
                },
                {
                    value: "选项4",
                    label: "龙须面",
                },
                {
                    value: "选项5",
                    label: "北京烤鸭",
                },
            ],


            cpu: [],//cpu
            memory: [],//内存



            tools: [],// 常用工具
            inputModel: "",//输入的产品型号
            value: "",
            cpuId: '',
            memoryId: '',
            systemIdModel: '',
            peripheralId: '',
            toolId: "",

            conditions: [], // 电脑产品驱动
            peripheral: [],//外设

            seriesList: [],// 系列数据
            seriesId: "",// 系列id
            model: [],//型号
            modelId: '', // 型号id
            system: [],//系统
            systemId: '',// 系统id

            psList: [],// 外设系列数据
            psId: "",// 外设系列id
            pModel: [],//外设型号
            pmId: '', // 外设型号id
            pSystem: [],//外设系统
            pSysId: '',// 外设系统id




            conditionsResult: [],// 电脑产品驱动结果
            modelResult: [],// 型号查询结果
            peripheralResult: [],// 外设查询结果
            toolResult: [],// 常用工具的查询结果

            drawer: false,
            list: [],
            navbarList: [{ title: "自助服务", path: "/index/serve/index" }, { title: '驱动下载', path: '' }]
        };
    },
    // components: {
    //     Select
    // },
    watch: {
        tabsIndex: function (newVal) {
            newVal == 2 ? this.toolSearch() : newVal == 1 ? this.findDriveCategory(2, "psList") : ""
        },
        // 系列id 改变
        seriesId: function (newVal) {
            this.modelId = ""
            this.systemId = ""
            this.conditionsResult = []
            this.findDriveCategory(null, "model", newVal)
        },
        modelId: function (newVal) {
            // this.conditionsSearch()
            this.systemId = ""
            this.conditionsResult = []
            this.findDriveCategory(null, "system", newVal)
        },
        systemId: function (newVal) {
            newVal ? this.conditionsSearch() : ""
            // newVal ? this.findDriveCategory(null, "conditionsResult", newVal) : ""
        },
        psId: function (newVal) {
            this.pmId = ""
            this.pSysId = ""
            this.peripheralResult = []
            this.findDriveCategory(null, "pModel", newVal)
        },
        pmId: function (newVal) {
            console.log(newVal)
            this.pSysId = ""
            this.peripheralResult = []
            this.findDriveCategory(null, "pSystem", newVal)
        },
        pSysId: function (newVal) {
            newVal ? this.peripheralSearch() : ""
        },

        cpuId: function () {
            this.conditionsSearch()
        },
        memoryId: function () {
            this.conditionsSearch()
        },

        systemIdModel: function () {
            if (this.inputModel != '') {
                this.modelSearch()
            }
        },
        peripheralId: function () {
            this.peripheralSearch()
        },
        toolId: function () {
            this.toolSearch()
        }
    },
    created() {
        // this.getDriveCategory()
        this.findDriveCategory(1, "seriesList")
        // this.findDriveCategory(2, "peripheral")
        // this.findDriveCategory(3, "tools")
    },
    methods: {
        openPopup(ref) {
            this.$refs[ref].open();
        },
        selectFuncSeries(item) {
            this.modelId = ""
            this.systemId = ""
            this.seriesId = item.value
        },
        selectFunc1(item) {
            this.systemId = ""
            this.modelId = item.value
        },
        selectFunc2(item) {
            this.cpuId = item.value
        },
        selectFunc3(item) {
            this.memoryId = item.value
        },
        selectFunc4(item) {
            this.systemId = item.value
        },
        selectFunc5(item) {
            this.systemIdModel = item.value
        },
        selectFunc6(item) {
            this.peripheralId = item.value
        },
        selectFunc7(item) {
            this.toolId = item.value
        },
        selectPSeries(item) {
            this.psId = item.value
            this.pmId = ""
            this.pSysId = ""
        },
        selectPModel(item) {
            this.pmId = item.value
            this.pSysId = ""
        },
        selectPSystem(item) {
            this.pSysId = item.value
        },
        // 转换（移动端需要根据value值显示对应的结果）
        transformSelect(list, value) {
            let result = list.filter(item => {
                return value == item.value
            })
            return result[0].label
        },
        // 获取驱动分类 
        findDriveCategory(type, array, driveCategoryId) {
            let params = {
                type
            }
            driveCategoryId ? params.driveCategoryId = driveCategoryId : ""
            this.$request({
                method: "get",
                url: this.$requestPath.findDriveCategory,
                params
            }).then(res => {
                console.log("获取驱动分类", res)
                res.data.forEach(self => {
                    self.value = self.driveCategoryId
                    self.label = self.categoryName
                })
                this[array] = []
                this[array].push(...res.data)
                console.log("this[array]", this[array])
            }).catch(error => {
                console.log("获取驱动分类错误", error)
            })
        },

        // 获取驱动查询条件
        getDriveCategory() {
            this.$request({
                method: "get",
                url: this.$requestPath.getDriveCategory,
                data: {}
            }).then(res => {
                console.log("获取驱动查询条件", res)
                this.conditions = []
                this.peripheral = []
                this.tools = []
                let peripheral = []
                res.data.forEach(item => {
                    switch (item.categoryName) {
                        case "电脑产品型号":
                            this.conditions.push(...item.driveCategoryDtos)
                            break;
                        case "驱动外设":
                            peripheral.push(...item.driveCategoryDtos)
                            break;
                        case "常用工具":
                            this.tools.push(...item.driveCategoryDtos)

                    }
                })
                // console.log("peripheral", peripheral)
                this.conditions.forEach(item => {
                    this.judgeCoditions(item)
                })
                peripheral.forEach(item => {
                    this.peripheral.push(...item.driveCategoryDtos)
                })
                this.peripheral.push(...peripheral)
                this.peripheral.forEach(item => {
                    item.value = item.driveCategoryId
                    item.label = item.categoryName
                })
                // console.log('peripheral', this.peripheral)
                this.tools.forEach(item => {
                    item.value = item.driveCategoryId
                    item.label = item.categoryName
                })

            }).catch(error => {
                console.log("获取驱动查询条件", error)
            })
        },

        //电脑产品驱动分类
        judgeCoditions(item) {
            item.driveCategoryDtos.forEach(self => {
                self.value = self.driveCategoryId
                self.label = self.categoryName
            })
            switch (item.categoryName) {
                case "型号":
                    this.model = []
                    this.model.push(...item.driveCategoryDtos)
                    break;
                case "cpu":
                    this.cpu = []
                    this.cpu.push(...item.driveCategoryDtos)
                    break;
                case "内存":
                    this.memory = []
                    this.memory.push(...item.driveCategoryDtos)
                    break;
                case "系统":
                    this.system = []
                    this.system.push(...item.driveCategoryDtos)
                    break;
            }
        },


        // 根据驱动id查询
        getByCategoryId(params, result) {
            // console.log(url, result)
            this.$request({
                method: "get",
                url: this.$requestPath.getByCategoryId,
                params
            }).then(res => {
                console.log("查询", res)
                if (res.code == 0) {
                    this[result] = []
                    this[result].push(...res.data)
                } else {
                    this.$message.error(res.msg);
                }
            }).catch(erorr => {
                console.log("查询error", erorr)
            })
        },
        // 电脑产品驱动
        conditionsSearch() {
            let params = {}
            this.cpuId ? params.cpuId = this.cpuId : ""
            this.modelId ? params.modelId = this.modelId : ""
            this.memoryId ? params.ramId = this.memoryId : ""
            this.systemId ? params.systemId = this.systemId : ""

            this.getByCategoryId(params, "conditionsResult")
        },
        // 型号查询
        modelSearch() {
            let params = {}
            this.modelId ? params.modelId = this.inputModel : ""
            this.systemId ? params.systemId = this.systemId : ""
            this.getByCategoryId(params, "modelResult")
        },
        // 外设查询
        peripheralSearch() {
            let params = {}
            // this.peripheralId ? params.typeId = this.peripheralId : ""
            this.pSysId ? params.typeId = this.pSysId : ""
            this.getByCategoryId(params, "peripheralResult")
        },
        // 常用工具查询
        toolSearch() {
            let params = { toolId: "333" }
            // this.toolId ? params.toolId = this.toolId : ""
            this.getByCategoryId(params, "toolResult")
        },
        // 处理时间
        dealWith(date) {
            let arr = date.split(" ")
            return arr[0]
        },
        // 下载文件
        download(item) {
            let { driveLinks } = item
            let link = document.createElement('a')  // 创建a标签
            link.style.display = 'none'
            link.href = driveLinks + '?response-content-type=application/octet-stream' // 设置下载地址
            link.setAttribute('download', '') // 添加downLoad属性
            document.body.appendChild(link)
            link.click()
        }

    },
};
</script>

<style lang="scss" scoped>
.page_box {
    max-width: var(--max-width);
    margin-left: var(--margin-base);
    margin-right: var(--margin-base);
}

.tabs {
    background: #f1eff2;
    user-select: none;
}

.table_title {
    background: #e8e8e8;
}

.li_item {
    width: 25%;
}

.search_btn {
    border: 0.0625rem solid var(--primary);
}

@media screen and (max-width: 640px) {
    .path {
        font-size: 0.875rem;
    }

    .drive_title_box {
        margin: 0.625rem 0px;

        img {
            width: 1.25rem;
        }

        .drive_title {
            font-size: 0.9375rem;
            margin-left: 0.375rem;
            display: flex;
            padding-top: 0.0625rem;
        }
    }

    .tabs {
        height: 1.75rem;
    }

    .select_item {
        width: 49%;
        margin-top: 0.3125rem;
    }

    .select_item_two {
        width: 49%;
        margin: 0.3125rem 0;
    }

    .search_box {
        height: 2.1875rem;
        border: 0.0625rem solid #ccc;
        padding: 0 0.625rem;
        margin: 0.3125rem 0;

        .search_btn {
            font-size: 0.9375rem;
            width: 3.75rem;
        }
    }

    .table {
        margin-top: 0.625rem;

        .table_title {
            height: 2.1875rem;
            font-size: 0.8125rem;
            padding: 0px 0.625rem;
        }

        ul {
            li {
                height: 1.875rem;
                padding: 0px 0.625rem;
                font-size: 0.75rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }

            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .path {
        font-size: 1rem;
    }

    .drive_title_box {
        margin: 0.625rem 0px;

        img {
            width: 1.4375rem;
        }

        .drive_title {
            font-size: 1rem;
            margin-left: 0.375rem;
            display: flex;
            padding-top: 0.0625rem;
        }
    }

    .select_item {
        width: 33.33%;
        // width: 50%;
    }

    .select_item_two {
        width: 33.33%;
    }

    .search_box {
        height: 2.1875rem;
        border: 0.0625rem solid #ccc;
        padding: 0 0.625rem;

        .search_btn {
            font-size: 0.9375rem;
            width: 3.75rem;
        }
    }

    .tabs {
        height: 2.5rem;
        margin-bottom: 0.625rem;
    }

    .table {
        margin-top: 0.625rem;

        .table_title {
            height: 2.5rem;
            padding: 0px 0.625rem;
        }

        ul {
            li {
                height: 2.375rem;
                padding: 0px 0.625rem;
                font-size: 0.875rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }

            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}

.tabitem-enter-active,
.tabitem-leave-active {
    transition: all 0.6s ease;
}

.tabitem-enter,
.tabitem-leave-to {
    opacity: 0;
    transform: translateX(0.625rem);
}

.result-enter-active,
.result-leave-active {
    transition: all 0.6s ease;
}

.result-enter,
.result-leave-to {
    opacity: 0;
    transform: translateX(0.625rem);
}
</style>
